import {
  formatISODate,
  getIsWithinInstantUpdateThreshold,
  userHasLegacyPlan,
} from '../../utils';

import {
  SuccessfulBillingActionModal,
  SuccessBillingActionModalContext,
} from '@/app/shared/components/success-modal';
import { useSubscribedUser } from '@/features/auth/hooks';
import type { ModalProps } from '@/ui';
import {
  MAIN_APP_DASHBOARD_URL,
  MAIN_APP_MIGRATION_URL,
} from '@/shared/constants';
import { useCRM } from '@/features/crm/hooks/use-crm.hook';

type SubscriptionChangeSuccessModalProps = {
  isUpgrade: boolean;
  newLoginSeatCount: number;
  newNotifySeatCount: number;
};

type Props = ModalProps<'div'> & SubscriptionChangeSuccessModalProps;

export const SubscriptionChangeSuccessModal = ({
  isUpgrade: isUpgradeProp,
  newLoginSeatCount,
  newNotifySeatCount,
  ...props
}: Props) => {
  const crm = useCRM();
  const user = useSubscribedUser();

  const isWithinInstantUpdateThreshold = getIsWithinInstantUpdateThreshold(
    user.activeSubscription.expirationDate,
  );

  const isUpgrade = isUpgradeProp || isWithinInstantUpdateThreshold;

  const hasScheduledSeatDowngrade =
    isUpgrade &&
    !isWithinInstantUpdateThreshold &&
    (newLoginSeatCount < user.activeSubscription.extraSeatCount ||
      newNotifySeatCount < user.activeSubscription.extraNotifySeatCount);

  const redirectUrl =
    userHasLegacyPlan(user) && isUpgrade
      ? MAIN_APP_MIGRATION_URL
      : MAIN_APP_DASHBOARD_URL;

  const handleOnClick = () => {
    crm.sendEvent({ name: 'subscription_downgrade_scheduled' });
  };

  return (
    <SuccessfulBillingActionModal.Modal {...props} onClose={() => false}>
      <SuccessfulBillingActionModal.Content>
        <SuccessfulBillingActionModal.Header
          title={isUpgrade ? undefined : 'Subscription change scheduled'}
        />
        {hasScheduledSeatDowngrade ? (
          <p>
            Your payment was processed successfully and your subscription will
            be upgraded any second.
            <br />
            Additionally, there are changes to your extra seats which will take
            effect on{' '}
            <strong>
              {formatISODate(user.activeSubscription.expirationDate)}
            </strong>{' '}
            (your renewal date).
          </p>
        ) : null}
        {!hasScheduledSeatDowngrade && isUpgrade ? (
          <p>
            Your payment was processed successfully and your subscription will
            be upgraded any second.
          </p>
        ) : null}
        {!hasScheduledSeatDowngrade && !isUpgrade ? (
          <p>
            The change in your subscription has been scheduled.
            <br />
            The change will take effect on{' '}
            <strong>
              {formatISODate(user.activeSubscription.expirationDate)}
            </strong>{' '}
            (your renewal date). You can cancel the scheduled change anytime.
          </p>
        ) : null}
        <SuccessBillingActionModalContext.Consumer>
          {({ isDisabled }) => (
            <SuccessfulBillingActionModal.Footer
              isDisabled={isDisabled}
              onClick={handleOnClick}
              link={redirectUrl}
            />
          )}
        </SuccessBillingActionModalContext.Consumer>
      </SuccessfulBillingActionModal.Content>
    </SuccessfulBillingActionModal.Modal>
  );
};
